import api from '@/utils/api'
import { ThemeContext } from '@/utils/context/Theme.context'
import { ActionIcon, Box, Image, Loader, Modal, MultiSelect, Paper, Popover, Select, Skeleton, Text, ThemeIcon, Tooltip, Transition } from '@mantine/core'
import { useHover, useMediaQuery } from '@mantine/hooks'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { AccountTypes, AnyService, Theme } from '../../../../types/global'
import Error from '../Error'
import { Check, Minus, PlayerSkipForward, Ban, CircleX, CirclePlus, Plus } from 'tabler-icons-react'
import { GrantsStatus } from './GrantsContainer'
import { InviteContext } from '@/utils/context/Invite.context'
import { markServiceGranted } from '@/utils/helpers/markAsGranted'
import { PlatformsContext } from '@/utils/context/Platforms.context'
import lang from '@/../public/translations/invite.json'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { assetNouns } from '@/static/assetNouns'
import AssetCreationGuideRenderer from './AssetCreationGuideRenderer'
import { assetCreationGuides } from '@/static/assetCreationGuides'

type SelectorProps = {
    businessName: string,
    inviteID: string,
    serviceConfig: { [key in string]: string[] },
    setServiceConfigs: (newConfig: { [key in string]: string[] | null }) => void,
    setResouceLabels?: (labels: Record<string, string>) => void
    service: AnyService,
    apiRoute: string,
    setAccounts?: (a: Array<{ account_id: string, id: string }>) => void,
    __facebookPagesForInstagramValidation?: Array<{ id: string, account_id: string }>,
    label?: string,
    fullWidth?: boolean,
    disableSkip?: boolean,
    description?: string
}

type ConflictingInstagramPageLink = {
    page: { name: string, id: string },
    instagramAccount: { name: string, id: string }
}

export const Selector = ({
    service,
    businessName,
    inviteID,
    setServiceConfigs,
    serviceConfig,
    apiRoute,
    setAccounts: forwardAccountsToParent,
    label,
    fullWidth,
    disableSkip,
    description,
    setResouceLabels
}: SelectorProps) => {
    const invite = useContext(InviteContext)
    const metaBusinessIDRef = useRef(invite.creds?.Meta?.business?.id)
    const theme = useContext(ThemeContext)
    const vertical = useMediaQuery("(max-width: 460px)")
    const platforms = useContext(PlatformsContext)
    const [selectedAccounts, setSelctedAccounts] = useState<string[] | null>(serviceConfig[service] || [])
    const [skipButtonLoading, setSkipButtonLoading] = useState(false)
    const { ref: selectorContinerRef, hovered: selectorContainerHovered } = useHover()
    const [accounts, setAccounts] = useState<Array<{
        account_id: string, id: string, page?: string
    }> | undefined>(undefined)
    const [error, setError] = useState('')
    const { lang: l } = useContext(InviteClientContext)
    const [assetCreationGuideOpen, setAssetCreationGuideOpen] = useState(false)

    const multiselectRef = useRef()

    const fetchAccounts = useCallback(async () => {
        console.log("api route:", apiRoute, service)
        if (!apiRoute) return;
        setError("")
        setAccounts(undefined)
        await api.post<any>(apiRoute, { // URL is grant URL
            inviteID: inviteID
        }).then((res) => {
            if (res.error) {
                return setError(res.msg)
            }
            setAccounts(res.data)
        })
    }, [inviteID])

    useEffect(() => {
        if (selectedAccounts?.length !== (serviceConfig[service] || [])?.length) {
            setServiceConfigs({
                [service]: selectedAccounts
            })
        }
    }, [selectedAccounts])

    useEffect(() => {
        if (setResouceLabels) {
            setResouceLabels((selectedAccounts || []).reduce((prev, current) => ({ ...prev, [current]: accounts?.find((a) => a.id === current)?.account_id || businessName || "" }), {}))
        }
    }, [serviceConfig])

    useEffect(() => {
        if (!forwardAccountsToParent) return;
        if (!accounts) return;
        forwardAccountsToParent(accounts)
    }, [accounts])

    useEffect(() => {
        fetchAccounts()
    }, [])

    useEffect(() => {
        if (!accounts) return;
        console.log("Fetching accounts")
        if (invite.creds?.Meta?.business?.id !== metaBusinessIDRef.current) {
            fetchAccounts()
        }
        metaBusinessIDRef.current = invite.creds?.Meta?.business?.id
    }, [invite])

    return (
        <>

            <Modal
                opened={assetCreationGuideOpen}
                onClose={() => setAssetCreationGuideOpen(false)}
                title={<Box className='flex' style={{ gap: 10 }}>
                    <Image style={{ width: 20, height: 20, objectFit: "contain" }} src={`/images/logos/${service.toLowerCase().replaceAll(" ", "_")}.png`} />
                    <Box className='flex fdc' style={{ gap: 6 }}>
                        <Text lh={1} fw={600}>{lang.assetCreationModal.title[l]} {assetNouns[service]}</Text>
                        <Text fz="xs" c="dimmed" lh={1}>{lang.assetCreationModal.subtitle[l]} {assetNouns[service]}</Text>
                    </Box>
                </Box>}
                centered
                styles={{ header: { minHeight: 0 } }}
            >
                <AssetCreationGuideRenderer guide={assetCreationGuides[service]!} close={() => setAssetCreationGuideOpen(false)} refreshAssets={fetchAccounts} service={service} />
            </Modal>



            <Box w={fullWidth ? "100%" : undefined} ref={selectorContinerRef}>
                {!accounts && !error
                    ? <Skeleton mt={vertical ? 10 : 0} w={fullWidth ? "100%" : 200} h={40} />
                    : <>
                        {!accounts?.length
                            ? <Box h={45} style={{ textAlign: fullWidth ? "left" : "right" }} className='flex jcc fdc'>
                                <Text fw={500} fz="xs" c="dimmed">No {assetNouns[service]}s found</Text>
                                <Box className='flex aic' style={{ gap: 5, justifyContent: fullWidth ? "flex-start" : "flex-end" }}>
                                    <Box className='flex'>
                                        {assetCreationGuides[service]
                                            ? <Text fz="xs" c="dimmed">
                                                <Text onClick={() => setAssetCreationGuideOpen(true)} className='cp' style={{ textDecoration: "underline" }} span fz="xs" c="blue">{lang.assetCreationButton[l]}</Text> or
                                            </Text>
                                            : null
                                        }

                                        <Box ml={4} className='flex aic' style={{ gap: 4 }}>
                                            {skipButtonLoading
                                                ? <Loader size={12} />
                                                : null
                                            }
                                        </Box>


                                        <Text className='cp' onClick={async () => {
                                            const platform = platforms.find((p) => p.services.find((s) => s.name === service))?.platform
                                            setSkipButtonLoading(true)
                                            await markServiceGranted(inviteID, platform as AccountTypes, service, undefined, "skipped")
                                            setSkipButtonLoading(false)
                                        }} fz="xs" style={{ textDecoration: "underline" }}>{skipButtonLoading ? lang.accountSelector.skipButton.loading[l] : lang.accountSelector.skipButton.initial[l]}</Text>
                                    </Box>
                                </Box>
                            </Box>
                            : <>
                                <Box w={fullWidth ? "100%" : undefined} className='flex aic' ref={selectorContinerRef} style={{ gap: 10 }}>
                                    {!disableSkip
                                        ? <Transition mounted={selectorContainerHovered} transition={"pop"}>
                                            {(styles) => (
                                                <Box style={{ ...styles }}>
                                                    <Tooltip label={lang.accountSelector.skipButotnTooltip[l]}>
                                                        <ActionIcon loading={skipButtonLoading} onClick={async () => {
                                                            setSkipButtonLoading(true)
                                                            const platform = platforms.find((p) => p.services.find((s) => s.name === service))?.platform
                                                            await markServiceGranted(inviteID, platform as AccountTypes, service, undefined, "skipped")
                                                            setSkipButtonLoading(false)
                                                        }} size={"sm"}>
                                                            <CircleX size={18} />
                                                        </ActionIcon>

                                                    </Tooltip>
                                                </Box>
                                            )}
                                        </Transition>
                                        : null
                                    }

                                    <Tooltip offset={{ mainAxis: 40 }} withArrow opened={true} maw={300} multiline position="right" disabled={!error} label={error} color="red">
                                        {/* @ts-ignore */}
                                        <MultiSelect ref={multiselectRef} description={description} label={label} w={fullWidth ? "100%" : undefined} radius={10} maw={fullWidth ? undefined : 205} mt={vertical ? 10 : 0} color={theme.color || "dark"} placeholder={error ? lang.accountSelector.noAccounts[l] : (!accounts?.length ? lang.accountSelector.noAccounts[l] : lang.accountSelector.accountsPresent[l])} key={service} clearable value={selectedAccounts || undefined} onChange={(e) => {
                                            setSelctedAccounts(e)
                                            // @ts-ignore
                                            multiselectRef.current?.blur()
                                        }} data={accounts?.map((acc) => ({ value: acc.id, label: `${acc.account_id || businessName} (${acc.id})` })) || []} />
                                    </Tooltip>
                                </Box>
                            </>
                        }
                    </>
                }
            </Box>
        </>
    )
}

export const GrantedChip = ({ skipped }: { skipped?: boolean }) => {
    const theme = useContext(ThemeContext)
    const vertical = useMediaQuery("(max-width: 460px)")
    const { lang: l } = useContext(InviteClientContext)
    return (
        <Paper h={"fit-content"} mt={vertical ? 5 : 0} w={"fit-content"} radius={100} p={5} px={10} className="flex aic" style={{ gap: 5 }} withBorder>
            <ThemeIcon variant={"light"} size="sm" color={skipped ? "gray" : (theme.color || "green")} style={{ borderRadius: 100 }}>
                {skipped
                    ? <Minus size={20} />
                    : <Check size={20} />
                }

            </ThemeIcon>
            <Text fw={500} fz="sm">{skipped ? (lang.grantChip.skipped[l]) : (lang.grantChip.granted[l])}</Text>
        </Paper>
    )
}