import manualInstructions, { Instruction } from "@/static/manualInstructions"
import { numberIcons } from "@/static/numberIcons"
import api from "@/utils/api"
import { InviteContext } from "@/utils/context/Invite.context"
import { InviteClientContext } from "@/utils/context/InviteClient.context"
import { ThemeContext } from "@/utils/context/Theme.context"
import getButtonThemeProps from "@/utils/helpers/getButtonThemeProps"
import { markServiceGranted } from "@/utils/helpers/markAsGranted"
import { Accordion, ActionIcon, Box, Button, Divider, Image, Modal, Paper, Radio, Text, Tooltip } from "@mantine/core"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { CircleCheck, ExternalLink, HandFinger, Mouse, X } from "tabler-icons-react"
import { AccountTypes, AmazonServices, AnyService, ClientLanguage, GoogleServices, HubSpotServices, KlaviyoServices, LinkedInServices, MetaServices, PinterestServices, ProfitMetricsServices, ServiceConfig, ShopifyServices, SnapchatServices, TwitterServices } from "../../../../types/global"
import { GrantsStatus } from "./GrantsContainer"
import lang from '@/../public/translations/manual.json'
import { useHover, useMediaQuery } from "@mantine/hooks"
import { PlatformsContext } from "@/utils/context/Platforms.context"

export type ManualAccessInstructionsRendererProps = {
    serviceConfigs: ServiceConfig<AnyService>,
    isLogged: boolean,
    updateGrantsStatus: (newGrants: GrantsStatus) => void,
    updateServiceConfig: (newServiceConfig: any) => void
    isGranted: boolean,
    oAuthProviderButton?: React.ReactElement,
    assetSelector?: React.ReactElement
}
type ManualAccessInstructionsRenderer = (params: ManualAccessInstructionsRendererProps) => React.ReactElement

const InstructionsAccordionRenderer = ({ instructions, apiConfirmedGranted, renderItem, renderRightSection, indexResolver, renderCondition, metadata, updateGrantsStatus, isGranted, step: controlledStep, setStep: setControlledStep, l }: {
    updateGrantsStatus: (newGrants: GrantsStatus) => void,
    metadata: {
        inviteID: string,
        platform: AccountTypes,
        service: AnyService
    },
    isGranted: boolean
    instructions: Array<Instruction>,
    step?: string,
    setStep?: (s: string) => void
    renderCondition: (instruction: Instruction) => boolean
    indexResolver?: (index: number, renderOrder: number) => number
    renderItem: ({ instruction, setStep, step, controls }: { instruction: Instruction, setStep: (step: string) => void, step: string, controls: React.ReactElement }) => React.ReactElement | null,
    renderRightSection?: ({ instruction }: { instruction: Instruction }) => React.ReactElement,
    l: ClientLanguage,
    apiConfirmedGranted?: boolean
}) => {
    const platforms = useContext(PlatformsContext)
    const platformService = useMemo(() => platforms.find((p) => p.platform === metadata.platform)?.services.find((s) => s.name === metadata.service), [platforms])
    const theme = useContext(ThemeContext)
    const [step, setStep] = useState(controlledStep || "0")

    useEffect(() => {
        console.log("Step is", step)
        if (setControlledStep) {
            setControlledStep(step)
        }
    }, [step])

    useEffect(() => {
        if (controlledStep) {
            setStep(controlledStep)
        }
    }, [controlledStep])

    const ContinueButton = ({ instruction }: { instruction: Instruction }) => {
        const isFinalStep = parseInt(step) === (instructions.filter((i) => renderCondition(i))).length - 1 && (indexResolver ? indexResolver(instruction.index, instructions.findIndex((i) => i.title === instruction.title)) : instruction.index) == ((instructions.filter((i) => renderCondition(i))).length - 1)  // Some maual grants are completed automatically 
        const isFinalUnconditionalStep = parseInt(step) === instructions.length - 1
        console.log(instruction.title, isFinalStep, step, (instructions.filter((i) => renderCondition(i))).length - 1, (indexResolver ? indexResolver(instruction.index, instructions.findIndex((i) => i.title === instruction.title)) : instruction.index) == ((instructions.filter((i) => renderCondition(i))).length - 1))
        const [loading, setLoading] = useState(false)
        return (
            <Tooltip disabled={!isFinalStep || !isGranted} position="bottom" withArrow label="You've already marked this service as granted">
                <Button color={(theme.color || "dark")} disabled={(platformService?.requiresAPIConfirmation && isFinalStep && !apiConfirmedGranted) || (isFinalStep && isGranted)} loading={loading} leftSection={isFinalStep ? <CircleCheck size={18} /> : null} onClick={async () => {
                    if (isFinalStep) {
                        setLoading(true)
                        await markServiceGranted(metadata.inviteID, metadata.platform, metadata.service as any, updateGrantsStatus).then(() => {
                            setStep((parseInt(step) + 1).toString())
                        }).finally(() => {
                            setLoading(false)
                        })
                    } else {
                        setStep((parseInt(step) + 1).toString())
                    }
                }}>{isFinalStep ? (platformService?.requiresAPIConfirmation && !apiConfirmedGranted ? (isFinalUnconditionalStep ? lang.waitForConfirmation[l] : lang.continueButton[l]) : lang.grantAccessButton[l]) : lang.continueButton[l]}</Button>
            </Tooltip>
        )
    }
    return (
        <Accordion chevronSize={0} variant={"default"} px={0} radius={15} defaultValue={step}
            // @ts-ignore
            onChange={(s) => s === null ? void 0 : setStep(s)}

            value={step}>
            {instructions?.map((instruction, i) => {

                if (!renderCondition(instruction)) return null;
                const resolvedIndex = indexResolver ? indexResolver(instruction.index, i) : instruction.index
                return <>
                    <Accordion.Item key={i} value={resolvedIndex.toString()}>
                        <Accordion.Control
                            className="manual_grant_accordion_control"
                            styles={{ control: { backgroundColor: "transparent !important" } }}
                            px={0}
                            icon={<Box className="flex aic" style={{ gap: 5 }}>
                                {React.cloneElement(numberIcons[resolvedIndex], { size: 18, style: { minWidth: 18 } })}
                                <Divider orientation="vertical" />
                            </Box>}

                        >
                            <Box className="flex aic jcsb" style={{ marginRight: 5, zIndex: 9999, position: "relative" }}>
                                <Text fw={600}>{instruction.title[l]}</Text>
                                {renderRightSection ? <div style={{ zIndex: 999 }}>
                                    {renderRightSection({ instruction })}
                                </div> : null}
                            </Box>
                        </Accordion.Control>
                        <Accordion.Panel px={0} styles={{ content: { paddingTop: 0, paddingLeft: 0, paddingRight: 0 } }}>
                            <div className="manualInstructionContainer">
                                {renderItem({
                                    instruction,
                                    setStep,
                                    step,
                                    controls: <Box className="flex" style={{ gap: 5 }} mt={15}>
                                        <Button onClick={() => setStep((parseInt(step) - 1).toString())} color={theme.color} variant={"outline"} disabled={!parseInt(step)}>{lang.backButton[l]}</Button>
                                        <ContinueButton instruction={instruction} />
                                    </Box>

                                })}

                            </div>

                        </Accordion.Panel>
                    </Accordion.Item>

                </>
            })}

        </Accordion>
    )
}

const VideoRenderer = ({ path, setOpen }: { path: string, setOpen: (o: boolean) => void }) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const { hovered, ref } = useHover()
    const videoRef = useRef<HTMLVideoElement>()
    const mobile = useMediaQuery("(max-width: 600px)")
    // const 
    useEffect(() => {
        setIsPlaying(hovered)

    }, [hovered])

    useEffect(() => {
        if (isPlaying) {
            videoRef.current?.play()
        } else {
            videoRef.current?.pause()
        }
    }, [isPlaying])

    return (
        <Box ref={ref} style={{ position: "relative", height: "fit-content" }}>
            <Box onClick={() => setIsPlaying(!isPlaying)} style={{ position: "absolute", opacity: isPlaying ? 0 : 1, top: 0, transition: "all .2s", left: 0, background: "rgba(0,0,0,.5)", width: "100%", height: "100%", borderRadius: 10, zIndex: 120 }} className="flex aic jcc fdc">
                {mobile
                    ? <HandFinger size={20} color="#fff" />
                    : <Mouse size={20} color="#fff" />
                }

                <Text fw={500} c="#fff">{!mobile ? "Hover" : "Click"} to play video</Text>
            </Box>
            {/* @ts-ignore */}
            <video ref={videoRef} onClick={() => setOpen(true)} loop src={path} autoPlay muted controls={false} style={{ borderRadius: 10, overflow: "hidden", boxShadow: "0px 0px 5px rgba(0,0,0,.1)", cursor: "zoom-in", width: "100%", display: "block" }}></video>
        </Box>
    )
}
export const InstructionalImageRenderer = ({ path }: { path: string }) => {
    const [open, setOpen] = useState(false)


    return (
        <>
            {open
                ? <ActionIcon onClick={() => setOpen(false)} color={"#fff"} style={{ position: "fixed", right: 20, top: 20, zIndex: 300 }} >
                    <X />
                </ActionIcon>
                : null
            }
            <Modal
                opened={open}
                onClose={() => setOpen(false)}
                withCloseButton={false}
                centered
                size={"xl"}
                zIndex={200}
                styles={{ body: { padding: 0, background: "transparent" }, content: { background: "transparent" } }}
            >
                {path.endsWith(".mp4")
                    ? <video onClick={() => setOpen(true)} src={path} autoPlay muted controls={false} style={{ borderRadius: 10, overflow: "hidden", boxShadow: "0px 0px 5px rgba(0,0,0,.1)", width: "100%", display: "block" }}></video>
                    : <Image mt={10} style={{ borderRadius: 10, overflow: "hidden", boxShadow: "0px 0px 5px rgba(0,0,0,.1)" }} alt="Instructional image" src={path} />
                }

            </Modal>
            <Box mt={10}>
                {path.endsWith(".mp4")
                    ? <VideoRenderer setOpen={setOpen} path={path} />
                    : <Image onClick={() => setOpen(true)} mt={10} style={{ overflow: "hidden", boxShadow: "0px 0px 5px rgba(0,0,0,.1)", cursor: "zoom-in" }} alt="Instructional image" src={path} />
                }

            </Box>

        </>
    )
}

export const GoogleSearchConsoleInstructions: ManualAccessInstructionsRenderer = ({
    // inviteID,
    // inviteData,
    updateGrantsStatus,
    isGranted,
    oAuthProviderButton,
    assetSelector,
    serviceConfigs
}) => {
    const theme = useContext(ThemeContext)
    const invite = useContext(InviteContext)
    const client = useContext(InviteClientContext)
    const [inviteEmail, setInviteEmail] = useState("")

    useEffect(() => {
        api.get<string>("/invite/public/details/google/email", {
            inviteID: invite.id,
            service: "Google Search Console" as AnyService
        }).then((res) => {
            setInviteEmail(res.data)
        })
    }, [])

    console.log(assetSelector)

    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: invite.id,
                platform: "Google",
                service: "Google Search Console" as GoogleServices
            }} instructions={manualInstructions["Google Search Console"] as Array<Instruction>}
                renderCondition={() => {
                    return true
                }}
                renderRightSection={({ instruction }) => {
                    console.log("Google:", instruction)
                    // if (!instruction.rightSection?.[client.lang]) return <></>
                    return <>{instruction.rightSection?.[client.lang]?.({
                        oAuthProviderButton, assetSelector, theme, accessLevel: invite.requestedAccounts["Google" as AccountTypes]["Google Search Console" as GoogleServices].accessLevel, inviteIdentifier: inviteEmail, creds: invite.creds
                    })}</>
                }}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({
                                oAuthProviderButton,
                                assetSelector,
                                theme,
                                inviteData: invite,
                                accessLevel: invite.requestedAccounts["Google" as AccountTypes]["Google Search Console" as GoogleServices].accessLevel,
                                inviteIdentifier: inviteEmail,
                                creds: invite.creds,
                                resource: serviceConfigs["Google Search Console"]
                            })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Google Search Console" as GoogleServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const YouTubeStudioInstructions: ManualAccessInstructionsRenderer = ({ isGranted, updateGrantsStatus }) => {
    const inviteData = useContext(InviteContext)
    const client = useContext(InviteClientContext)
    const [inviteEmail, setInviteEmail] = useState("")
    const [accountType, setAccountType] = useState<"personal" | "brand">()
    const theme = useContext(ThemeContext)
    useEffect(() => {
        api.get<string>("/invite/public/details/google/email", {
            inviteID: inviteData.id,
            service: "YouTube Studio" as AnyService
        }).then((res) => {
            setInviteEmail(res.data)
        })
    }, [])


    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Google",
                service: "YouTube Studio" as GoogleServices
            }} instructions={manualInstructions["YouTube Studio"] as Array<Instruction>}
                renderCondition={(instruction) => {
                    if (instruction.condition) {
                        if (instruction.condition.type === "account_type") {
                            if (instruction.condition.value !== accountType) {
                                return false
                            }
                        }
                    }
                    return true
                }}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({ creds: inviteData.creds, theme, accessLevel: inviteData.requestedAccounts["Google" as AccountTypes]["YouTube Studio" as GoogleServices].accessLevel, inviteIdentifier: inviteEmail })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("YouTube Studio" as GoogleServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {instruction.decisionPoint
                                ? <Paper withBorder bg={theme.theme === "dark" ? "#171923" : undefined} className="flex fdc" style={{ gap: 10, marginTop: 10, borderRadius: 15 }} p={15}>
                                    {instruction.decisionPoint.choices.map((c) => {
                                        return <Radio color={theme.color || "dark"} styles={{ label: { fontWeight: '500' } }} checked={
                                            instruction.decisionPoint?.id === "account_type" ? accountType === c.value : false
                                        } label={c.label[client.lang]} onChange={(e) => {
                                            const checked = e.target.checked
                                            if (!checked) {
                                                return
                                            }
                                            if (instruction.decisionPoint?.id === "account_type") {
                                                setAccountType(c.value as any)
                                            }
                                        }} />
                                    })}
                                </Paper>
                                : null
                            }
                            {instruction.decisionPoint?.id === "account_type" && !accountType
                                ? null
                                : <>{controls}</>
                            }

                        </div>
                    )
                }} />
        </div>
    )
}

export const TwitterDelagateAccountInstructions: ManualAccessInstructionsRenderer = ({ updateGrantsStatus, isGranted }) => {
    const [inviteHandle, setInviteHandle] = useState("")
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)

    useEffect(() => {
        api.get<string>("/invite/public/details/twitter/handle", {
            inviteID: inviteData.id,
            service: "Twitter Delegate Account" as AnyService
        }).then((res) => {
            setInviteHandle(res.data)
        })
    }, [])

    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Twitter",
                service: "Twitter Delegate Account" as TwitterServices
            }} instructions={manualInstructions["Twitter Delegate Account"] as Array<Instruction>}
                renderCondition={() => {
                    return true
                }}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({ creds: inviteData.creds, theme, accessLevel: inviteData.requestedAccounts["Twitter" as AccountTypes]["Twitter Delegate Account" as TwitterServices].accessLevel, inviteIdentifier: inviteHandle })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Twitter Delegate Account" as TwitterServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const TwitterAdsInstructions: ManualAccessInstructionsRenderer = ({ updateGrantsStatus, isGranted }) => {
    const [inviteHandle, setInviteHandle] = useState("")
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)

    useEffect(() => {
        api.get<string>("/invite/public/details/twitter/handle", {
            inviteID: inviteData.id,
            service: "Twitter Ads" as AnyService
        }).then((res) => {
            setInviteHandle(res.data)
        })
    }, [])

    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Twitter",
                service: "Twitter Ads" as TwitterServices
            }} instructions={manualInstructions["Twitter Ads"] as Array<Instruction>}
                renderCondition={() => {
                    return true
                }}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({ creds: inviteData.creds, theme, accessLevel: inviteData.requestedAccounts["Twitter" as AccountTypes]["Twitter Ads" as TwitterServices].accessLevel, inviteIdentifier: inviteHandle })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Twitter Ads" as TwitterServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const InstagramInstructions: ManualAccessInstructionsRenderer = ({ updateGrantsStatus, isGranted, serviceConfigs, oAuthProviderButton, assetSelector }) => {
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)
    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Meta",
                service: "Instagram" as MetaServices
            }} instructions={manualInstructions["Instagram"] as Array<Instruction>}
                renderRightSection={({ instruction }) => {
                    if (!instruction.rightSection) return <></>;
                    return <>{instruction.rightSection[client.lang]({
                        creds: inviteData.creds, theme, accessLevel: inviteData.requestedAccounts["Meta" as AccountTypes]["Instagram" as MetaServices].accessLevel, resource: serviceConfigs["Instagram"],
                        oAuthProviderButton,
                        assetSelector
                    })}</>
                }}
                renderCondition={() => {
                    return true
                }}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {/* @ts-ignore */}
                            {instruction.description[client.lang]?.({ creds: inviteData.creds, accessLevel: inviteData.requestedAccounts["Meta" as AccountTypes]["Instagram" as MetaServices].accessLevel, resource: serviceConfigs["Instagram"] })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Instagram" as MetaServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const PinterestAdsInstructions: ManualAccessInstructionsRenderer = ({
    isGranted,
    serviceConfigs,
    updateGrantsStatus,
}) => {
    const client = useContext(InviteClientContext)
    const inviteData = useContext(InviteContext)
    const [businessID, setBusinessID] = useState("")

    useEffect(() => {
        api.get<string>("/invite/public/details/pinterest/business-id", {
            inviteID: inviteData.id,
            service: "Pinterest Ads" as AnyService
        }).then((res) => {
            console.log("Setting business id to ", res.data)
            setBusinessID(res.data)
        })
    }, [])


    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Pinterest",
                service: "Pinterest Ads" as PinterestServices
            }} instructions={manualInstructions["Pinterest Ads"] as Array<Instruction>}
                renderCondition={() => {
                    return true
                }}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {/* @ts-ignore */}
                            {instruction.description[client.lang]?.({ creds: inviteData.creds, accessLevel: inviteData.requestedAccounts["Pinterest" as AccountTypes]["Pinterest Ads" as PinterestServices].accessLevel, resource: serviceConfigs["Pinterest Ads" as PinterestServices], inviteIdentifier: businessID })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Pinterest Ads" as PinterestServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const MetaAdsInstructions: ManualAccessInstructionsRenderer = ({
    serviceConfigs,
    isGranted,
    updateGrantsStatus,
    oAuthProviderButton,
    assetSelector
}) => {
    const [businessID, setBusinessID] = useState("")
    const [resourceLabels, setResourceLabels] = useState<Record<string, string>>({})
    const [apiConfirmedGranted, setAPIConfirmedGranted] = useState(false)
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)

    useEffect(() => {
        api.get<string>("/invite/public/details/meta/partner-id", {
            inviteID: inviteData.id,
            service: "Meta Ads" as AnyService
        }).then((res) => {
            setBusinessID(res.data)
        })
    }, [])
    console.log(oAuthProviderButton)
    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer apiConfirmedGranted={apiConfirmedGranted} l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Meta",
                service: "Meta Ads"
            }} instructions={manualInstructions["Meta Ads"] as Array<Instruction>}
                renderCondition={({ index }) => {
                    if (index < 1) return true;
                    if (serviceConfigs["Meta Ads"]?.length) return true;
                    return false;
                }}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {/* @ts-ignore */}
                            {instruction.description[client.lang]?.({
                                oAuthProviderButton, creds: inviteData.creds, accessLevel: inviteData.requestedAccounts["Meta"]["Meta Ads"].accessLevel, resource: serviceConfigs["Meta Ads"], inviteIdentifier: businessID,
                                assetSelector: React.cloneElement(assetSelector || <></>, { setResouceLabels: setResourceLabels }),
                                inviteData,
                                resourceLabels: resourceLabels,
                                setAPIConfirmedGranted
                            })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Meta Ads" as MetaServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }}
                renderRightSection={({ instruction }) => {
                    if (!instruction.rightSection) return <></>;

                    return <>{instruction.rightSection[client.lang]({
                        assetSelector,
                        oAuthProviderButton, theme, creds: inviteData.creds, accessLevel: inviteData.requestedAccounts["Meta"]["Meta Ads"].accessLevel, resource: serviceConfigs["Meta Ads"], inviteIdentifier: businessID
                    })}</>

                }}
            />
        </div>
    )
}

export const AmazonAdsInstructions: ManualAccessInstructionsRenderer = ({
    isGranted,
    serviceConfigs,
    updateGrantsStatus,
    updateServiceConfig,
}) => {
    const inviteData = useContext(InviteContext)
    const [step, setStep] = useState("0")
    const [aprovalLink, setAprovalLink] = useState("")
    const [clientAccountID, setClientAccountID] = useState("")
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)
    const { lang: l } = client
    useEffect(() => {
        if (inviteData.requestedAccounts["Amazon"]["Amazon Ads"].grantURI) {
            setAprovalLink(inviteData.requestedAccounts["Amazon"]["Amazon Ads"].grantURI)
        }
    }, [inviteData])

    useEffect(() => {
        if (inviteData.creds?.Amazon?.user_id) {
            updateServiceConfig({
                "Amazon Ads": [inviteData.creds?.Amazon?.user_id]
            })
        }
    }, [inviteData])

    useEffect(() => {
        if (serviceConfigs["Amazon Ads"]) {
            setStep("2")
        }
    }, [serviceConfigs])

    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} step={step} setStep={setStep} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData?.id || "xxx",
                platform: "Amazon",
                service: "Amazon Ads" as AmazonServices
            }} instructions={manualInstructions["Amazon Ads"] as Array<Instruction>}
                renderCondition={(instruction) => {
                    if (instruction.index > 1 && !serviceConfigs["Amazon Ads"]) {
                        return false
                    }
                    return true
                }}
                renderItem={({ instruction, controls, }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({
                                creds: inviteData?.creds,
                                theme,
                                accessLevel: inviteData.requestedAccounts["Amazon" as AccountTypes]["Amazon Ads" as AmazonServices].accessLevel,
                                resource: serviceConfigs["Amazon Ads" as AmazonServices],
                                form: [
                                    { id: "client_account_id", label: "Account ID", value: [clientAccountID] },
                                ],
                                agencyName: inviteData.agency.name,
                                setFormValue(id, val) {
                                    if (id === "client_account_id") {
                                        setClientAccountID(val[0])
                                    }
                                },
                                inviteIdentifier: aprovalLink
                            })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Amazon Ads" as AmazonServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {step === "1"
                                ? <Box mt={15} className="flex aic" style={{ gap: 5 }}>
                                    <Button onClick={() => setStep((parseInt(step) - 1).toString())} variant={"default"} disabled={!parseInt(step)}>Back</Button>
                                    <Button color={theme.color || "dark"} disabled={!clientAccountID} onClick={() => {
                                        api.post("/invite/public/manual/form/amazon-ads-account-id", {
                                            inviteID: inviteData.id,
                                            identifier: clientAccountID
                                        })
                                        setStep("2")
                                    }}>Continue</Button>
                                </Box>
                                : <>
                                    {step === "2"
                                        ? <>
                                            {aprovalLink
                                                ? <Button color={theme.color || "dark"} mt={10} leftSection={<ExternalLink size={20} />} fullWidth
                                                    onClick={() => {
                                                        markServiceGranted(inviteData.id, "Amazon", "Amazon Ads", updateGrantsStatus)
                                                        window.open(aprovalLink, "_blank")
                                                    }}
                                                >{lang.grantAccessButton[l]}</Button>
                                                : null
                                            }
                                        </>
                                        : <>{controls}</>
                                    }

                                </>
                            }

                        </div>
                    )
                }} />
        </div>
    )
}

export const ShopifyCollaboratorInstructions: ManualAccessInstructionsRenderer = ({
    isGranted,
    updateGrantsStatus,
    updateServiceConfig,
    serviceConfigs
}) => {
    const inviteData = useContext(InviteContext)
    const [step, setStep] = useState("0")
    const [aprovalLink, setAprovalLink] = useState("")
    const [clientAccountID, setClientAccountID] = useState("")
    const [storeURL, setStoreURL] = useState("")
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)
    const { lang: l } = client
    useEffect(() => {
        console.log("Grant URI:", inviteData.requestedAccounts["Shopify"]["Shopify Store Collaborator"].grantURI)
        if (inviteData.requestedAccounts["Shopify"]["Shopify Store Collaborator"].grantURI) {
            setAprovalLink(inviteData.requestedAccounts["Shopify"]["Shopify Store Collaborator"].grantURI)
        }
    }, [inviteData])

    useEffect(() => {
        if (inviteData.creds?.Shopify?.user_id && inviteData.creds?.Shopify?.shopify_store_url) {
            updateServiceConfig({
                "Shopify Store Collaborator": [inviteData.creds?.Amazon?.user_id, inviteData.creds?.Shopify?.shopify_store_url]
            })
        }
    }, [inviteData])

    useEffect(() => {
        if (serviceConfigs["Shopify Store Collaborator"]) {
            setStep("3")
        }
    }, [serviceConfigs])



    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} step={step} setStep={setStep} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Shopify",
                service: "Shopify Store Collaborator" as ShopifyServices
            }} instructions={manualInstructions["Shopify Store Collaborator"] as Array<Instruction>}
                renderCondition={(instruction) => {
                    if (instruction.index > 2 && !serviceConfigs["Shopify Store Collaborator"]) {
                        return false
                    }
                    return true
                }}
                renderItem={({ instruction, controls, step }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({
                                creds: inviteData.creds,
                                theme,
                                accessLevel: inviteData.requestedAccounts["Shopify" as AccountTypes]["Shopify Store Collaborator" as ShopifyServices].accessLevel,
                                resource: serviceConfigs["Shopify Store Collaborator" as ShopifyServices],
                                form: [
                                    { id: "client_account_id", label: lang.shopifyStoreCollaboratorRequestCode[l], value: [clientAccountID], placeholder: lang.shopifyStoreCollaboratorPastePrompt[l] },
                                    { id: "store_url", label: lang.shopifyStoreURL[l], value: [storeURL] }
                                ],
                                agencyName: inviteData.agency.name,
                                setFormValue(id, val) {
                                    if (id === "client_account_id") {
                                        setClientAccountID(val[0])
                                    } else if (id === "store_url") {
                                        setStoreURL(val[0])
                                    }
                                },
                                inviteIdentifier: aprovalLink
                            })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Shopify Store Collaborator" as AmazonServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {step === "2" || step === "1"
                                ? <Box mt={15} className="flex aic" style={{ gap: 5 }}>
                                    <Button {...getButtonThemeProps(theme)} onClick={() => setStep((parseInt(step) - 1).toString())} variant={"default"} disabled={!parseInt(step)}>Back</Button>
                                    <Button {...getButtonThemeProps(theme)} disabled={step === "2" ? !storeURL : !clientAccountID} onClick={() => {
                                        if (step === "1") {
                                            api.post("/invite/public/manual/form/shopify-collaborator-account-id", {
                                                inviteID: inviteData.id,
                                                identifier: clientAccountID
                                            })
                                        } else {
                                            api.post("/invite/public/manual/form/shopify-collaborator-update-store-url", {
                                                inviteID: inviteData.id,
                                                identifier: storeURL
                                            })
                                        }
                                        setStep(step === "1" ? "2" : "3")
                                    }}>{lang.continueButton[l]}</Button>
                                </Box>
                                : <>
                                    {step === "3"
                                        ? <>
                                            {aprovalLink
                                                ? <Button color={theme.color || "dark"} mt={10} leftSection={<ExternalLink size={20} />} fullWidth
                                                    onClick={() => {
                                                        markServiceGranted(inviteData.id, "Shopify", "Shopify Store Collaborator", updateGrantsStatus)
                                                        window.open("https://admin.shopify.com", "_blank")
                                                    }}
                                                >{lang.grantAccessButton[l]}</Button>
                                                : null
                                            }

                                        </>
                                        : <>{controls}</>
                                    }

                                </>
                            }

                        </div>
                    )
                }} />
        </div>
    )
}

export const SnapchatBusinessInstructions: ManualAccessInstructionsRenderer = ({ isGranted, updateGrantsStatus }) => {
    const [inviteEmail, setInviteEmail] = useState("")
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)
    useEffect(() => {
        api.get<string>("/invite/public/details/snapchat/email", {
            inviteID: inviteData.id,
            service: "Snapchat Business" as AnyService
        }).then((res) => {
            setInviteEmail(res.data)
        })
    }, [])

    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Snapchat",
                service: "Snapchat Business" as SnapchatServices
            }} instructions={manualInstructions["Snapchat Business"] as Array<Instruction>}
                renderCondition={() => true}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({ creds: inviteData.creds, theme, agencyName: inviteData.agency.name, inviteData: inviteData, accessLevel: inviteData.requestedAccounts["Snapchat" as AccountTypes]["Snapchat Business" as SnapchatServices].accessLevel, inviteIdentifier: inviteEmail })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Snapchat Business" as SnapchatServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const SnapchatAdsInstructions: ManualAccessInstructionsRenderer = ({
    isGranted, updateGrantsStatus
}) => {
    const inviteData = useContext(InviteContext)
    const [inviteEmail, setInviteEmail] = useState("")
    const [isInvited, setIsInvited] = useState<"yes" | "no" | undefined>(undefined)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)
    useEffect(() => {
        api.get<string>("/invite/public/details/snapchat/email", {
            inviteID: inviteData.id,
            service: "Snapchat Ads" as AnyService
        }).then((res) => {
            setInviteEmail(res.data)
        })
    }, [])
    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={() => {
                if (inviteData.requestedAccounts.Snapchat["Snapchat Business"]?.requested) {
                    return updateGrantsStatus({
                        "Snapchat Ads": {
                            granted: true
                        },
                        "Snapchat Business": {
                            granted: true
                        }
                    })
                }
                updateGrantsStatus({
                    "Snapchat Ads": {
                        granted: true
                    }
                })
            }} metadata={{
                inviteID: inviteData.id,
                platform: "Snapchat",
                service: "Snapchat Ads" as SnapchatServices
            }} instructions={manualInstructions["Snapchat Ads"] as Array<Instruction>}
                renderCondition={(instruction) => {
                    if (instruction.condition) {
                        if (instruction.condition.type === "is_invited") {
                            if (instruction.condition.value !== isInvited) {
                                return false
                            }
                        }
                    }
                    return true
                }}
                indexResolver={(index, renderOrder) => {
                    if (renderOrder >= 4 && isInvited === "no") {
                        return index + 2
                    }
                    return index
                }}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({ creds: inviteData.creds, theme, agencyName: inviteData.agency.name, inviteData: inviteData, accessLevel: inviteData.requestedAccounts["Snapchat" as AccountTypes]["Snapchat Ads" as SnapchatServices].accessLevel, inviteIdentifier: inviteEmail })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Snapchat Ads" as SnapchatServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {instruction.decisionPoint
                                ? <Paper withBorder bg={theme.theme === "dark" ? "#171923" : undefined} className="flex fdc" style={{ gap: 10, marginTop: 10, borderRadius: 15 }} p={15}>
                                    {instruction.decisionPoint.choices.map((c) => {
                                        return <Radio color={theme.color || "dark"} styles={{ label: { fontWeight: '500' } }} checked={
                                            instruction.decisionPoint?.id === "is_invited" ? isInvited === c.value : false
                                        } label={c.label[client.lang]} onChange={(e) => {
                                            const checked = e.target.checked
                                            if (!checked) {
                                                return
                                            }
                                            if (instruction.decisionPoint?.id === "is_invited") {
                                                setIsInvited(c.value as any)
                                            }
                                        }} />
                                    })}
                                </Paper>
                                : null
                            }
                            {instruction.decisionPoint?.id === "is_invited" && !isInvited
                                ? null
                                : <>{controls}</>
                            }

                        </div>
                    )
                }} />
        </div>
    )
}

export const HubSpotPartnerInstructions: ManualAccessInstructionsRenderer = ({
    isGranted, updateGrantsStatus
}) => {
    const inviteData = useContext(InviteContext)
    const [partnerLink, setPartnerLink] = useState("")
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)
    useEffect(() => {
        api.get<string>("/invite/public/details/hubspot/partner-link", {
            inviteID: inviteData.id,
            service: "HubSpot Partner" as AnyService
        }).then((res) => {
            setPartnerLink(res.data)
        })
    }, [])
    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={() => {
                updateGrantsStatus({
                    "HubSpot Partner": {
                        granted: true
                    }
                })
            }} metadata={{
                inviteID: inviteData.id,
                platform: "HubSpot",
                service: "HubSpot Partner" as HubSpotServices
            }} instructions={manualInstructions["HubSpot Partner"] as Array<Instruction>}
                renderCondition={() => true}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({ creds: inviteData.creds, theme, agencyName: inviteData.agency.name, inviteData: inviteData, accessLevel: inviteData.requestedAccounts["HubSpot" as AccountTypes]["HubSpot Partner" as HubSpotServices].accessLevel, inviteIdentifier: partnerLink })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("HubSpot Partner" as HubSpotServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            <>{controls}</>

                        </div>
                    )
                }} />
        </div>
    )
}

export const ProfitMetricsPartnerInstructions: ManualAccessInstructionsRenderer = ({ isGranted, updateGrantsStatus }) => {
    const [agencyName, setAgencyName] = useState("")
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)
    useEffect(() => {
        api.get<string>("/invite/public/details/profitmetrics/agency", {
            inviteID: inviteData.id,
            service: "ProfitMetrics Partner" as AnyService
        }).then((res) => {
            setAgencyName(res.data)
        })
    }, [])

    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "ProfitMetrics",
                service: "ProfitMetrics Partner" as ProfitMetricsServices
            }} instructions={manualInstructions["ProfitMetrics Partner"] as Array<Instruction>}
                renderCondition={() => true}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({ creds: inviteData.creds, theme, agencyName: inviteData.agency.name, inviteData: inviteData, accessLevel: inviteData.requestedAccounts["ProfitMetrics" as AccountTypes]["ProfitMetrics Partner" as ProfitMetricsServices].accessLevel, inviteIdentifier: agencyName })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("ProfitMetrics Partner" as ProfitMetricsServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const LinkedInCompanyPageInstructions: ManualAccessInstructionsRenderer = ({ isGranted, updateGrantsStatus }) => {
    const [username, setUsername] = useState<string[]>([])
    const [profileURLs, setProfileURLs] = useState<string[]>([])
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)
    useEffect(() => {
        api.get<string[]>("/invite/public/details/linkedin/username", {
            inviteID: inviteData.id,
            service: "LinkedIn Company Page" as AnyService
        }).then((res) => {
            setUsername(res.data)
        })
    }, [])

    useEffect(() => {
        api.get<string[]>("/invite/public/details/linkedin/profile-url", {
            inviteID: inviteData.id,
            service: "LinkedIn Company Page" as AnyService
        }).then((res) => {
            setProfileURLs(res.data)
            console.log("Setting profile url", res.data)
        })
    }, [])

    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "LinkedIn",
                service: "LinkedIn Company Page" as LinkedInServices
            }} instructions={manualInstructions["LinkedIn Company Page"] as Array<Instruction>}
                renderCondition={() => true}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({
                                creds: inviteData.creds,
                                theme,
                                agencyName: inviteData.agency.name,
                                inviteData: inviteData,
                                accessLevel: inviteData.requestedAccounts["LinkedIn" as AccountTypes]["LinkedIn Company Page" as LinkedInServices].accessLevel,
                                // @ts-ignore LinkedIn is an exception in that it supports inviting multiple accounts manually
                                inviteIdentifier: username,
                                resource: profileURLs
                            })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Linked In Company Page").toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const LinkedInAdsInstructions: ManualAccessInstructionsRenderer = ({ isGranted, updateGrantsStatus }) => {
    const [usernames, setUsernames] = useState<string[]>([])
    const [profileURLs, setProfileURLs] = useState<string[]>([])
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)
    useEffect(() => {
        api.get<string[]>("/invite/public/details/linkedin/username", {
            inviteID: inviteData.id,
            service: "LinkedIn Ads" as AnyService
        }).then((res) => {
            setUsernames(res.data)
        })
    }, [])
    useEffect(() => {
        api.get<string[]>("/invite/public/details/linkedin/profile-url", {
            inviteID: inviteData.id,
            service: "LinkedIn Ads" as AnyService
        }).then((res) => {
            setProfileURLs(res.data)
            console.log("Setting profile url", res.data)
        })
    }, [])
    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "LinkedIn",
                service: "LinkedIn Ads" as LinkedInServices
            }} instructions={manualInstructions["LinkedIn Ads"] as Array<Instruction>}
                renderCondition={() => true}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({
                                creds: inviteData.creds,
                                theme,
                                agencyName: inviteData.agency.name,
                                inviteData: inviteData,
                                accessLevel: inviteData.requestedAccounts["LinkedIn" as AccountTypes]["LinkedIn Ads" as LinkedInServices].accessLevel,
                                // @ts-ignore LinkedIn is an exception in that it supports inviting multiple accounts manually
                                inviteIdentifier: usernames,
                                resource: profileURLs
                            })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Linked In Ads").toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const KlaviyoAccountInstructions: ManualAccessInstructionsRenderer = ({ isGranted, updateGrantsStatus }) => {
    const [email, setEmail] = useState<string>("")
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)
    useEffect(() => {
        api.get<string>("/invite/public/details/klaviyo/email", {
            inviteID: inviteData.id,
            service: "Klaviyo Account" as AnyService
        }).then((res) => {
            setEmail(res.data)
        })
    }, [])
    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Klaviyo",
                service: "Klaviyo Account" as KlaviyoServices
            }} instructions={manualInstructions["Klaviyo Account"] as Array<Instruction>}
                renderCondition={() => true}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({
                                creds: inviteData.creds,
                                theme,
                                agencyName: inviteData.agency.name,
                                inviteData: inviteData,
                                accessLevel: inviteData.requestedAccounts["Klaviyo" as AccountTypes]["Klaviyo Account" as KlaviyoServices].accessLevel,
                                inviteIdentifier: email,
                            })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Klaviyo Account" as KlaviyoServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const GoogleBusinessProfileInstructions: ManualAccessInstructionsRenderer = ({ isGranted, updateGrantsStatus }) => {
    const [email, setEmail] = useState<string>("")
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)

    useEffect(() => {
        api.get<string>("/invite/public/details/google/email", {
            inviteID: inviteData.id,
            service: "Google Business Profile" as AnyService
        }).then((res) => {
            setEmail(res.data)
        })
    }, [])
    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Google",
                service: "Google Business Profile" as GoogleServices
            }} instructions={manualInstructions["Google Business Profile"] as Array<Instruction>}
                renderCondition={() => true}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({
                                creds: inviteData.creds,
                                theme,
                                agencyName: inviteData.agency.name,
                                inviteData: inviteData,
                                accessLevel: inviteData.requestedAccounts["Google" as AccountTypes]["Google Business Profile" as GoogleServices].accessLevel,
                                inviteIdentifier: email,
                            })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Google Business Profile" as GoogleServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}

export const MetaPixelsManualInstructions: ManualAccessInstructionsRenderer = ({ isGranted, updateGrantsStatus }) => {
    const [businessID, setBusinessID] = useState("")
    const inviteData = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const client = useContext(InviteClientContext)

    useEffect(() => {
        api.get<string>("/invite/public/details/meta/partner-id", {
            inviteID: inviteData.id,
            service: "Meta Pixels" as AnyService
        }).then((res) => {
            setBusinessID(res.data)
        })
    }, [])

    return (
        <div className="flex fdc" style={{ gap: 15 }}>
            <InstructionsAccordionRenderer l={client.lang} isGranted={isGranted} updateGrantsStatus={updateGrantsStatus} metadata={{
                inviteID: inviteData.id,
                platform: "Meta",
                service: "Meta Pixels" as MetaServices
            }} instructions={manualInstructions["Meta Pixels"] as Array<Instruction>}
                renderCondition={() => true}
                renderItem={({ instruction, controls }) => {
                    return (
                        <div>
                            {instruction.description[client.lang]?.({
                                creds: inviteData.creds,
                                theme,
                                agencyName: inviteData.agency.name,
                                inviteData: inviteData,
                                accessLevel: inviteData.requestedAccounts["Meta" as AccountTypes]["Meta Pixels" as MetaServices].accessLevel,
                                inviteIdentifier: businessID,
                            })}
                            {instruction.image &&
                                <InstructionalImageRenderer path={`/images/invite/guides/${("Meta Pixels" as MetaServices).toLowerCase().replaceAll(" ", "_")}/${instruction.image}`} />
                            }
                            {controls}
                        </div>
                    )
                }} />
        </div>
    )
}